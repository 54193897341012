import {
  getQueryStringParameter,
  clearInputField,
  clearSelectField
} from "../../../helpers";

import {
  setupPoliticallyExposedForm,
  resetPoliticallyExposedForm
} from "../politically_exposed_person_form.js";

const refreshBeneficiariesFormTitle = ($form) => {
  $("#beneficiaries .beneficiary .beneficiary-number", $form).each((index, element) => {
    element.textContent = index + 1;
  });
};

const toggleAddButton = ($form) => {
  const $beneficiaries = $("#beneficiaries", $form);
  const maxNumberOfBeneficiaries = parseInt($beneficiaries.data("max-number-of-beneficiaries"));

  $("#add-beneficiary", $form).toggleClass("d-none", $(".beneficiary", $beneficiaries).length >= maxNumberOfBeneficiaries);
};

const setupFiscalResidenceFields = ($form) => {
  let fiscalResidence = ".fiscal-residence";
  let $fiscalResidence = $(fiscalResidence, $form);
  let fiscalResidenceFields = ".fiscal-residence-fields";
  let $fiscalResidenceFields = $(fiscalResidenceFields, $fiscalResidence);
  let fiscalResidenceNifCodeField = ".fiscal-residence-nif-code-field";
  let $fiscalResidenceNifCodeInput = $(`${fiscalResidenceNifCodeField} input[type=text]`, $fiscalResidenceFields);
  let fiscalResidenceWithoutNifField = ".fiscal-residence-without-nif-field";
  let $fiscalResidenceWithoutNifCheckbox = $(`${fiscalResidenceWithoutNifField} input[type=checkbox]`, $fiscalResidenceFields);
  let $removeFiscalResidenceButton = $(".remove-fiscal-residence-country", $fiscalResidenceFields);
  let fiscalResidenceFeedback = ".invalid-feedback";
  let $fiscalResidenceFeedback = $(fiscalResidenceFeedback, $fiscalResidenceFields);
  let addFiscalResidenceButton = ".add-fiscal-residence";
  let $addFiscalResidenceButton = $(addFiscalResidenceButton, $fiscalResidence);

  $fiscalResidenceWithoutNifCheckbox.on("change", (e) => {
    let $fields = $(e.target).closest(fiscalResidenceFields);
    let $nifCodeInput = $(`${fiscalResidenceNifCodeField} input[type=text]`, $fields);

    if (e.target.checked) {
      $nifCodeInput.attr("disabled", "disabled");
      clearInputField($nifCodeInput[0]);
    } else {
      $nifCodeInput.removeAttr("disabled");
    }
  });

  $addFiscalResidenceButton.on("click", (e) => {
    e.preventDefault();

    let $addButton = $(e.target);
    let $residence = $addButton.closest(fiscalResidence);
    let $fields = $(fiscalResidenceFields, $residence);

    if ($fields.hasClass("d-none")) {
      $addButton.addClass("d-none");
      $fields.removeClass("d-none");
    }
  });

  $removeFiscalResidenceButton.on("click", (e) => {
    e.preventDefault();

    let $residence = $(e.target).closest(fiscalResidence);
    let $fields = $(fiscalResidenceFields, $residence);

    $fields.addClass("d-none");
    $(addFiscalResidenceButton, $residence).removeClass("d-none");

    $(`${fiscalResidenceNifCodeField} input[type=text]`, $fields).removeAttr("disabled");
    $(fiscalResidenceFeedback, $fields).each((_, element) => {
      $(element).addClass("d-none").html("");
    });

    $("input", $fields).each((_, element) => {
      clearInputField(element);
    });

    $("select", $fields).each((_, element) => {
      element.selectedIndex = 0

      clearSelectField(element);
    });
  });
};

const setupEventListeners = ($form, $beneficiaryForm = $form) => {
  if ($beneficiaryForm == $form) {
    $("#beneficiaries .beneficiary", $form).each((_, element) => {
      setupPoliticallyExposedForm($(element));
    });
  } else {
    setupPoliticallyExposedForm($beneficiaryForm);
  }

  let $removeButton = $(".remove-beneficiary-wrapper .remove-beneficiary", $form);

  $removeButton.on("click", (e) => {
    $(e.target).closest(".beneficiary").remove();

    toggleAddButton($form);
    refreshBeneficiariesFormTitle($form);
  });

  let $isLegalRepresentativeCheckBoxes = $('.is-legal-representative-block input[type="checkbox"]', $form);
  let $isSecondSignatoryCheckBoxes = $('.is-second-signatory-block input[type="checkbox"]', $form);

  $isLegalRepresentativeCheckBoxes.on("change", (e) => {
    if (e.target.checked) {
      let $checkbox = $(e.target);
      let $isSecondSignatoryCheckBox = $('.is-second-signatory-block input[type="checkbox"]', $checkbox.closest(".beneficiary"));

      $isLegalRepresentativeCheckBoxes.prop("checked", false);
      $isSecondSignatoryCheckBox.prop("checked", false);
      $checkbox.prop("checked", true);
    }
  });

  $isSecondSignatoryCheckBoxes.on("change", (e) => {
    if (e.target.checked) {
      let $checkbox = $(e.target);
      let $isLegalRepresentativeCheckBox = $('.is-legal-representative-block input[type="checkbox"]', $checkbox.closest(".beneficiary"));

      $isLegalRepresentativeCheckBox.prop("checked", false);
      $isSecondSignatoryCheckBoxes.prop("checked", false);
      $checkbox.prop("checked", true);
    }
  });

  setupFiscalResidenceFields($beneficiaryForm);
};

const updateFormControlName = (element, newID) => {
  let name = element.name;
  let indexMatches = name.match(/\[(\d+)\]/);

  if (indexMatches === null) {
    return;
  }

  let index = indexMatches[1];

  element.name = name.replace(new RegExp(`\\[${index}\\]`), `[${newID}]`);

  let elementID = element.id;

  if (elementID === "") {
    return;
  }

  const elementIDRegExp = new RegExp(`_${index}_`);

  let newElementID = elementID.replace(elementIDRegExp, `_${newID}_`);

  element.id = newElementID;

  let $formGroup = $(element).closest(".form-group");
  let $label = $(`label[for=${elementID}]`, $formGroup);

  if ($label.length === 0) {
    $label = $("label", $formGroup).filter((_, element) => {
      return elementIDRegExp.test(element.getAttribute("for"));
    });
  }

  if ($label.length > 0) {
    $label.attr("for", $label.attr("for").replace(elementIDRegExp, `_${newID}_`));
  }
};

const buildBeneficiaryFormTemplate = ($form) => {
  const $beneficiaryForm = $("#beneficiaries .beneficiary", $form).first();

  let $beneficiaryFormTemplate = $beneficiaryForm.clone();
  let newID = Date.now();

  let $fiscalResidenceFields = $(".fiscal-residence-fields", $beneficiaryFormTemplate);

  $fiscalResidenceFields.addClass("d-none");
  $(".fiscal-residence-nif-code-field input[type=text]", $fiscalResidenceFields).removeAttr("disabled");
  $(".add-fiscal-residence", $beneficiaryFormTemplate).removeClass("d-none");

  $("input", $beneficiaryFormTemplate).each((_, element) => {
    clearInputField(element);
    updateFormControlName(element, newID);
  });

  $("select", $beneficiaryFormTemplate).each((_, element) => {
    element.selectedIndex = 0;

    clearSelectField(element);
    updateFormControlName(element, newID);

    if (element.classList.contains("custom-select")) {
      element.removeAttribute("data-select2-id");
      element.removeAttribute("aria-hidden");
      element.removeAttribute("tabindex");

      $(element).next(".select2").remove();
    }
  });

  resetPoliticallyExposedForm($beneficiaryFormTemplate);

  $(".remove-beneficiary-wrapper", $beneficiaryFormTemplate).removeClass("d-none");
  $(".invalid-feedback", $beneficiaryFormTemplate).html("").addClass("d-none");

  return $beneficiaryFormTemplate;
};

$(document).ready(() => {
  const $form = $("form#legal-profile-form");

  if ($form.length === 0 || getQueryStringParameter("id") !== "ownership") {
    return;
  }

  const $beneficiaryFormTemplate = buildBeneficiaryFormTemplate($form);
  const $beneficiaries = $("#beneficiaries", $form);

  setupEventListeners($form);

  $("#add-beneficiary", $form).on("click", (e) => {
    let $newBeneficiaryForm = $beneficiaryFormTemplate.clone();
    let newID = Date.now()

    $("input, select", $newBeneficiaryForm).each((_, element) => {
      updateFormControlName(element, newID);
    });

    $("select.custom-select", $newBeneficiaryForm).select2();

    $beneficiaries.append($newBeneficiaryForm);

    toggleAddButton($form);
    refreshBeneficiariesFormTitle($form);
    setupEventListeners($form, $newBeneficiaryForm);
  });
});
