import {
  getQueryStringParameter,
  clearInputField
} from "../../helpers";

const areBadActorRulesHonored = ($inputs) => {
  return !$inputs.is((_, element) => {
    return element.checked && element.value === "true";
  });
};

$(document).ready(() => {
  const $form = $("form#natural-profile-form, form#legal-profile-form");

  if ($form.length === 0 || getQueryStringParameter("id") !== "bad_actor_rules") {
    return;
  }

  const hasObtainedADisqualificationWaiverFields = "#has-obtained-a-disqualification-waiver-fields";
  const $hasObtainedADisqualificationWaiverFields = $(hasObtainedADisqualificationWaiverFields, $form);
  const $hasObtainedADisqualificationWaiverRadioButtons = $("input[type=radio]", $hasObtainedADisqualificationWaiverFields);
  const $badActorRulesRadioButtons = $("input[type=radio]", $form).not($hasObtainedADisqualificationWaiverRadioButtons);

  $badActorRulesRadioButtons.on("change", (e) => {
    if (e.target.value === "true" && e.target.checked) {
      $hasObtainedADisqualificationWaiverFields.removeClass("d-none");
    } else if (areBadActorRulesHonored($badActorRulesRadioButtons)) {
      $hasObtainedADisqualificationWaiverFields.addClass("d-none");

      $("input", $hasObtainedADisqualificationWaiverFields).each((_, inputElement) => {
        clearInputField(inputElement);
      });
    } else {
      $hasObtainedADisqualificationWaiverFields.removeClass("d-none");
    }
  });
});
