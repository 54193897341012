import {
  getQueryStringParameter,
  clearInputField
} from "../../../helpers";

$(document).ready(() => {
  const $form = $("form#legal-profile-form");

  if ($form.length === 0 || getQueryStringParameter("id") !== "us_supplement") {
    return;
  }

  const $limitedPartnerCriteraRadioButtons = $("#limited-partner-critera-radio-buttons input[type=radio]", $form);
  const $otherLimitedPartnerCriteriaFields = $("#other-limited-partner-criteria-fields", $form);

  $limitedPartnerCriteraRadioButtons.on("change", (e) => {
    if (e.target.value === "other" && e.target.checked) {
      $otherLimitedPartnerCriteriaFields.removeClass("d-none");
    } else {
      $otherLimitedPartnerCriteriaFields.addClass("d-none");

      $("input", $otherLimitedPartnerCriteriaFields).each((_, inputElement) => {
        clearInputField(inputElement);
      });
    }
  });

  const limitedPartnerCategoriesRadioButtons = "#limited-partner-categories-radio-buttons";
  const qualifiedInstitutionalBuyerFields = "#qualified-institutional-buyer-fields";

  const $qualifiedInstitutionalBuyerFields = $(`${limitedPartnerCategoriesRadioButtons} ${qualifiedInstitutionalBuyerFields}`, $form);

  const $limitedPartnerCategoriesRadioButtons = $(`${limitedPartnerCategoriesRadioButtons} input[type=radio]`, $form).filter((_, element) => {
    return $(element).closest(qualifiedInstitutionalBuyerFields).length == 0;
  });

  const $otherLimitedPartnerCategoriesFields = $(`${limitedPartnerCategoriesRadioButtons} #other-limited-partner-categories-fields`, $form);

  $limitedPartnerCategoriesRadioButtons.on("change", (e) => {
    if (e.target.value === "qualified_institutional_buyer" && e.target.checked) {
      $qualifiedInstitutionalBuyerFields.removeClass("d-none");
    } else {
      $qualifiedInstitutionalBuyerFields.addClass("d-none");

      $("input", $qualifiedInstitutionalBuyerFields).each((_, inputElement) => {
        clearInputField(inputElement);
      });
    }

    if (e.target.value === "other" && e.target.checked) {
      $otherLimitedPartnerCategoriesFields.removeClass("d-none");
    } else {
      $otherLimitedPartnerCategoriesFields.addClass("d-none");

      $("input", $otherLimitedPartnerCategoriesFields).each((_, inputElement) => {
        clearInputField(inputElement);
      });
    }
  });
});
