import {
  getQueryStringParameter,
  clearInputField
} from "../../../helpers";

$(document).ready(() => {
  const $form = $("form#legal-profile-form");

  if ($form.length === 0 || getQueryStringParameter("id") !== "retail_client_classification") {
    return;
  }

  const $limitedPartnerIsRetailClientRadioButtonsContainer = $("#limited-partner-is-retail-client-radio-buttons", $form);
  const $limitedPartnerIsRetailClientRadioButtons = $("input[type=radio]", $limitedPartnerIsRetailClientRadioButtonsContainer);

  const $limitedPartnerIsProfessionalClientRadioButtonsContainer = $("#limited-partner-is-professional-client-radio-buttons", $form);
  const $limitedPartnerIsProfessionalClientRadioButtons = $("input[type=radio]", $limitedPartnerIsProfessionalClientRadioButtonsContainer);

  $limitedPartnerIsRetailClientRadioButtons.on("change", (e) => {
    if (e.target.checked) {
      $("#limited-partner-is-retail-client-nested-fields", $limitedPartnerIsRetailClientRadioButtonsContainer).removeClass("d-none");
      $("#professional-client-classification-fields", $limitedPartnerIsProfessionalClientRadioButtonsContainer).addClass("d-none");

      $("input", $limitedPartnerIsProfessionalClientRadioButtonsContainer).each((_, inputElement) => {
        clearInputField(inputElement);
      });
    }
  });

  $limitedPartnerIsProfessionalClientRadioButtons.on("change", (e) => {
    if (e.target.checked) {
      $("#limited-partner-is-retail-client-nested-fields", $limitedPartnerIsRetailClientRadioButtonsContainer).addClass("d-none");
      $("#professional-client-classification-fields", $limitedPartnerIsProfessionalClientRadioButtonsContainer).removeClass("d-none");

      $("input", $limitedPartnerIsRetailClientRadioButtonsContainer).each((_, inputElement) => {
        clearInputField(inputElement);
      });
    }
  });
});
