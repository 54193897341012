import {
  getQueryStringParameter,
  clearInputField
} from "../../../helpers";

import { setupFatcaFiscalCountries } from "../fatca_nif_inputs";

$(document).ready(() => {
  const $form = $("form#legal-profile-form");
  const step = getQueryStringParameter("id");

  if ($form.length === 0 || step !== "tax_details") {
    return;
  }

  setupFatcaFiscalCountries($form);

  const $accountHolderStatusRadioButtons = $("#account-holder-status-radio-buttons input[type=radio]", $form);
  const $financialInstitutionTypeFields = $("#financial-institution-type-fields", $form);
  const $activeNfeFields = $("#active-nfe-fields", $form);

  $accountHolderStatusRadioButtons.on("change", (e) => {
    if (e.target.value === "financial_institution") {
      if (e.target.checked) {
        $activeNfeFields.addClass("d-none");
        $financialInstitutionTypeFields.removeClass("d-none");

        $("input", $activeNfeFields).each((_, inputElement) => {
          clearInputField(inputElement);
        });
      } else {
        $financialInstitutionTypeFields.addClass("d-none");

        $("input", $financialInstitutionTypeFields).each((_, inputElement) => {
          clearInputField(inputElement);
        });
      }
    } else if (e.target.value === "active_nfe") {
      if (e.target.checked) {
        $financialInstitutionTypeFields.addClass("d-none");
        $activeNfeFields.removeClass("d-none");

        $("input", $financialInstitutionTypeFields).each((_, inputElement) => {
          clearInputField(inputElement);
        });
      } else {
        $activeNfeFields.addClass("d-none");

        $("input", $activeNfeFields).each((_, inputElement) => {
          clearInputField(inputElement);
        });
      }
    } else if (e.target.value === "passive_nfe" && e.target.checked) {
      $activeNfeFields.addClass("d-none");
      $financialInstitutionTypeFields.addClass("d-none");

      $("input", $activeNfeFields).each((_, inputElement) => {
        clearInputField(inputElement);
      });

      $("input", $financialInstitutionTypeFields).each((_, inputElement) => {
        clearInputField(inputElement);
      });
    }
  });
});
