import {
  getQueryStringParameter,
  clearInputField,
  clearSelectField,
} from '../helpers';

$(document).ready(() => {
  let $form = $("#new-investor-form");

  if ($form.length === 0) {
    return;
  }

  let $profileTypeRadioFormGroup = $("#profile-type-form-group", $form);
  let $legalNameFormGroup = $("#legal-profile-legal-name-form-group", $form);

  $("input[type=radio]", $profileTypeRadioFormGroup).on("change", (e) => {
    if (!e.target.checked) return;

    $legalNameFormGroup.toggleClass("d-none", e.target.value !== "Users::Profile::Legal");
  });

  $form.on("submit", (e) => {
    let $legalNameInput = $("input[type=text]", $legalNameFormGroup);
    $("input[type=radio]", $profileTypeRadioFormGroup).each((_, inputElement) => {
      if (inputElement.value === "Users::Profile::Legal" && !inputElement.checked) {
        clearInputField($legalNameInput[0]);
      }
    });
  });
});
