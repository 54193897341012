import {
  getQueryStringParameter,
  clearInputField
} from "../../../helpers";

$(document).ready(() => {
  const $form = $("form#natural-profile-form");

  if ($form.length === 0 || getQueryStringParameter("id") !== "origin_of_funds") {
    return;
  }

  const $originOfFundsRadio = $("#origin_of_funds_radio_buttons input[type=radio]", $form);
  const $otherOriginOfFundsField = $("#other_origin_of_funds_field", $form);

  $originOfFundsRadio.on("change", (e) => {
    if (!e.target.checked) return;

    if (e.target.value === "outside_metropolitan_france") {
      $otherOriginOfFundsField.removeClass("d-none");
    } else {
      $otherOriginOfFundsField.addClass("d-none");

      $("input", $otherOriginOfFundsField).each((_, inputElement) => {
        clearInputField(inputElement);
      });
    }
  });

  const $sourceOfFundsRadio = $("#source_of_funds_radio_buttons input[type=radio]", $form);
  const $otherSourceOfFundsField = $("#other_source_of_funds_field", $form);

  $sourceOfFundsRadio.on("change", (e) => {
    if (!e.target.checked) return;

    if (e.target.value === "other") {
      $otherSourceOfFundsField.removeClass("d-none");
    } else {
      $otherSourceOfFundsField.addClass("d-none");

      $("input", $otherSourceOfFundsField).each((_, inputElement) => {
        clearInputField(inputElement);
      });
    }
  });

  const $investmentObjectivesCheckBoxes = $("#investment_objectives_checkboxes input[type=checkbox]", $form);
  const $otherInvestmentObjectivesField = $("#other_investment_objectives_field", $form);

  $investmentObjectivesCheckBoxes.on("change", (e) => {
    if (e.target.value !== "other") return;

    if (e.target.checked) {
      $otherInvestmentObjectivesField.removeClass("d-none");
    } else {
      $otherInvestmentObjectivesField.addClass("d-none");

      $("input", $otherInvestmentObjectivesField).each((_, inputElement) => {
        clearInputField(inputElement);
      });
    }
  });
});
