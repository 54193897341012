import { getQueryStringParameter } from "../../../helpers";

$(document).ready(() => {
  const $form = $("form#legal-profile-form");

  if ($form.length === 0 || getQueryStringParameter("id") !== "professional_client_classification") {
    return;
  }

  const $limitedPartnerIsProfessionalClientRadioButtonsContainer = $("#limited-partner-is-professional-client-radio-buttons", $form);
  const $limitedPartnerIsProfessionalClientRadioButtons = $("input[type=radio]", $limitedPartnerIsProfessionalClientRadioButtonsContainer);

  $limitedPartnerIsProfessionalClientRadioButtons.on("change", (e) => {
    if (e.target.checked) {
      $("#professional-client-classification-fields", $limitedPartnerIsProfessionalClientRadioButtonsContainer).removeClass("d-none");
    }
  });
});
