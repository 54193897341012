import {
  getQueryStringParameter
} from "../../../helpers";

$(document).ready(() => {
  const $form = $("form#natural-profile-form");

  if ($form.length === 0 || getQueryStringParameter("id") !== "classification") {
    return;
  }

  const $limitedPartnerIsRetailClientRadioButtonsContainer = $("#limited_partner_is_retail_client_radio_buttons", $form);
  const $limitedPartnerIsRetailClientRadioButtons = $("input[type=radio]", $limitedPartnerIsRetailClientRadioButtonsContainer);

  $limitedPartnerIsRetailClientRadioButtons.on("change", (e) => {
    if (e.target.checked) {
      $("#limited_partner_is_retail_client_nested_fields", $limitedPartnerIsRetailClientRadioButtonsContainer).removeClass("d-none");
    }
  });
});
