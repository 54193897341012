import {
  getQueryStringParameter,
  clearInputField,
  clearSelectField,
} from '../../../helpers';

import { setupPoliticallyExposedForm } from "../politically_exposed_person_form.js";

import {
  phoneNumberFormatter,
  clearPhoneNumberInput
} from '../../../phoneNumberFormatter.js'

$(document).ready(() => {
  const $form = $('form#legal-profile-form');
  const step = getQueryStringParameter("id");

  if ($form.length === 0 || (step !== null && step !== "" && step !== "investor_identification" && !window.location.pathname.endsWith("new"))) {
    return;
  }

  setupPoliticallyExposedForm($form);

  const signatoryPhoneNumberInput = $form[0].querySelector("#signatory-phone-number-input");
  const signatoryPhoneNumberErrorMesage = $form[0].querySelector("#signatory-phone-number-error-message");

  phoneNumberFormatter(signatoryPhoneNumberInput, signatoryPhoneNumberErrorMesage);

  const legalRepresentativePhoneNumberInput = $form[0].querySelector("#legal-representative-phone-number-input");
  const legalRepresentativePhoneNumberErrorMesage = $form[0].querySelector("#legal-representative-phone-number-error-message");

  const secondSignatoryPhoneNumberInput = $form[0].querySelector("#second-signatory-phone-number-input");
  const secondSignatoryPhoneNumberErrorMesage = $form[0].querySelector("#second-signatory-phone-number-error-message");

  const legalRepresentativePhoneNumberInputInstance = phoneNumberFormatter(legalRepresentativePhoneNumberInput, legalRepresentativePhoneNumberErrorMesage);
  const secondSignatoryPhoneNumberInputInstance = phoneNumberFormatter(secondSignatoryPhoneNumberInput, secondSignatoryPhoneNumberErrorMesage);

  const $authorizedRepresentativeRadio = $("#is-authorized-representative-radio-buttons input[type=radio]", $form);
  const $legalRepresentativeFormFields = $("#legal-representative-form-fields", $form);

  $authorizedRepresentativeRadio.on("change", (e) => {
    if (!e.target.checked) return;

    $legalRepresentativeFormFields.toggleClass("d-none", e.target.value === "false");

    $("input", $legalRepresentativeFormFields).each((_, inputElement) => {
      clearInputField(inputElement);
    });

    clearPhoneNumberInput(legalRepresentativePhoneNumberInputInstance);
  });

  const $secondSignatoryFormFields = $("#second-signatory-form-fields", $form);
  const $addSecondSignatoryButton = $("#add-second-signatory", $form);
  const $removeSecondSignatoryButtonWrapper = $(".remove-second-signatory-wrapper", $secondSignatoryFormFields);
  const $removeSecondSignatoryButton = $(".remove-second-signatory-contact", $removeSecondSignatoryButtonWrapper);

  $addSecondSignatoryButton.on("click", (e) => {
    $secondSignatoryFormFields.removeClass("d-none");
    $addSecondSignatoryButton.addClass("d-none");
  });

  $removeSecondSignatoryButton.on("click", (e) => {
    $secondSignatoryFormFields.addClass("d-none");

    $("input", $secondSignatoryFormFields).each((_, inputElement) => {
      clearInputField(inputElement);
    });

    clearPhoneNumberInput(secondSignatoryPhoneNumberInputInstance);

    $addSecondSignatoryButton.removeClass("d-none");
  });

  $form.on("submit", (e) => {
    if ($secondSignatoryFormFields.hasClass("d-none")) {
      $secondSignatoryFormFields.remove();
    }
  });

  const $limitedPartnerIsBeneficiaryRadioButtons = $("#limited-partner-is-beneficiary-radio-buttons input[type=radio]", $form);
  const $limitedPartnerBeneficiaryDetails = $("#limited-partner-beneficiary-details", $form);

  $limitedPartnerIsBeneficiaryRadioButtons.on("change", (e) => {
    $limitedPartnerBeneficiaryDetails.toggleClass("d-none", !(e.target.value === "false" && e.target.checked));
  });
});
