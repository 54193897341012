import {
  clearInputField,
  clearSelectField,
} from "../../helpers";

$(document).ready(() => {
  const $form = $("form#investments-intentions-form");

  if ($form.length === 0) {
    return;
  }

  const $investmentsIntentionsCheckBoxes = $("#profile-investments-intentions-fields input[type=checkbox]", $form);
  const $otherInvestmentsIntentionsFields = $("#other-investments-intentions-fields", $form);

  $investmentsIntentionsCheckBoxes.on("change", (e) => {
    if (e.target.value !== "other") return;

    if (e.target.checked) {
      $otherInvestmentsIntentionsFields.removeClass("d-none");
    } else {
      $otherInvestmentsIntentionsFields.addClass("d-none");

      $("input", $otherInvestmentsIntentionsFields).each((_, inputElement) => {
        clearInputField(inputElement);
      });
    }
  });
});
