import {
  getQueryStringParameter
} from "../../helpers";

$(document).ready(() => {
  const $form = $("form#natural-profile-form, form#legal-profile-form");

  if ($form.length === 0 || getQueryStringParameter("id") !== "representations_and_warranties") {
    return;
  }

  const $investorIsBeneficialOwnerRadioButtons = $("#investor-is-beneficial-owner-radio-buttons input[type=radio]", $form);
  const $flowThroughEntityDetails = $("#flow-through-entity-details", $form);

  $investorIsBeneficialOwnerRadioButtons.on("change", (e) => {
    $flowThroughEntityDetails.toggleClass("d-none", !(e.target.value === "false" && e.target.checked));
  });
});
