import {
  getQueryStringParameter,
  clearInputField
} from "../../../helpers";

import { setupFatcaFiscalCountries } from "../fatca_nif_inputs";

$(document).ready(() => {
  const $form = $("form#natural-profile-form");

  if ($form.length === 0 || getQueryStringParameter("id") !== "tax_details") {
    return;
  }

  setupFatcaFiscalCountries($form);
});
