import {
  getQueryStringParameter,
  clearInputField
} from "../../helpers";

import {
  phoneNumberFormatter,
  clearPhoneNumberInput
} from "../../phoneNumberFormatter.js";

const buildContactFormTemplate = ($form) => {
  const $contactForm = $("#user-profile-contacts .user-profile-contact", $form).first();

  let $contactFormTemplate = $contactForm.clone();
  let $removeButtonWrapper = $(".remove-user-profile-contact-wrapper", $contactFormTemplate);

  $removeButtonWrapper.removeClass("d-none");

  $("input", $contactFormTemplate).each((_, element) => {
    clearInputField(element);

    element.name = element.name.replace(/\[\d+\]/, "[]");
    element.removeAttribute("id");
  });

  $("label", $contactFormTemplate).each((_, element) => {
    element.removeAttribute("for");
  });

  return $contactFormTemplate;
};

$(document).ready(() => {
  const $form = $("form#natural-profile-form, form#legal-profile-form");
  const step = getQueryStringParameter("id");

  if ($form.length === 0 || step !== "contacts") {
    return;
  }

  const $contactFormTemplate = buildContactFormTemplate($form);
  const $contacts = $("#user-profile-contacts", $form);
  const $addContactButton = $("#add-user-profile-contact", $form);

  $(".user-profile-contact .phone-number-input", $contacts).each((_, element) => {
    let $error = $(element).closest(".phone-number-form-group").find(".phone-number-error-message");

    phoneNumberFormatter(element, $error[0]);
  });

  $addContactButton.on("click", (e) => {
    let $newContactForm = $contactFormTemplate.clone();
    let nextContactID = Date.now();

    $("input", $newContactForm).each((_, element) => {
      element.name = element.name.replace(/\[\]/, `[${nextContactID}]`);
    });

    let $phoneNumberInput = $(".phone-number-input", $newContactForm);
    let $phoneNumberError = $(".phone-number-form-group .phone-number-error-message", $newContactForm);

    const iti = phoneNumberFormatter($phoneNumberInput[0], $phoneNumberError[0]);

    clearPhoneNumberInput(iti);

    const $removeButton = $(".remove-user-profile-contact-wrapper .remove-user-profile-contact", $newContactForm);

    $removeButton.on("click", (e) => {
      $(e.target).closest(".user-profile-contact").remove();
    });

    $contacts.append($newContactForm);
  });
});
