/**
 * Used to match `RegExp`
 * [syntax characters](http://ecma-international.org/ecma-262/7.0/#sec-patterns).
 */
const reRegExpChar = /[\\^$.*+?()[\]{}|]/g;
const reHasRegExpChar = RegExp(reRegExpChar.source);

/**
 * Escapes the `RegExp` special characters "^", "$", "\", ".", "*", "+",
 * "?", "(", ")", "[", "]", "{", "}", and "|" in `string`.
 *
 * @since 3.0.0
 * @category String
 * @param {string} [string=''] The string to escape.
 * @returns {string} Returns the escaped string.
 * @see escape, escapeRegExp, unescape
 * @example
 *
 * escapeRegExp("[lodash](https://lodash.com/)")
 * // => "\[lodash\]\(https://lodash\.com/\)"
 */
export const escapeRegExp = (string) => {
  if (string && reHasRegExpChar.test(string)) {
    return string.replace(reRegExpChar, "\\$&");
  } else {
    return (string || "");
  }
};
