import {
  getQueryStringParameter,
  clearInputField
} from "../../../helpers";

$(document).ready(() => {
  const $form = $("form#natural-profile-form");

  if ($form.length === 0 || getQueryStringParameter("id") !== "financial_information") {
    return;
  }

  const $totalAssetsRadio = $("#total_assets_radio_buttons input[type=radio]", $form);
  const $totalAssetsOtherFields = $("#total_assets_other_field", $form);

  $totalAssetsRadio.on("change", (e) => {
    if (e.target.value === "over_5m" && e.target.checked) {
      $totalAssetsOtherFields.removeClass("d-none");
    } else {
      $totalAssetsOtherFields.addClass("d-none");

      $("input", $totalAssetsOtherFields).each((_, inputElement) => {
        clearInputField(inputElement);
      });
    }
  });

  const $totalInvestmentsRadio = $("#total_investments_radio_buttons input[type=radio]", $form);
  const $totalInvestmentsOtherFields = $("#total_investments_other_field", $form);

  $totalInvestmentsRadio.on("change", (e) => {
    if (e.target.value === "over_5m" && e.target.checked) {
      $totalInvestmentsOtherFields.removeClass("d-none");
    } else {
      $totalInvestmentsOtherFields.addClass("d-none");

      $("input", $totalInvestmentsOtherFields).each((_, inputElement) => {
        clearInputField(inputElement);
      });
    }
  });

  const $assetsInputs = $("input[type=\"number\"].assets_input", $form);
  const $allocationOfAssetsInput = $("#allocation_of_assets", $form);

  $assetsInputs.on("change", (e) => {
    let allocationOfAssets = 0;

    $assetsInputs.each((_, element) => {
      let assetsValue = parseInt(element.value);
      if (isNaN(assetsValue)) assetsValue = 0;

      allocationOfAssets += assetsValue;
    });

    $allocationOfAssetsInput.val(allocationOfAssets);
  });
});
