import { phoneNumberFormatter } from "./phoneNumberFormatter.js";

$(document).ready(() => {
  const $form = $("form#distributor-form, form#distributor-invitation-form");

  if ($form.length === 0) {
    return;
  }

  const $phoneNumberInput = $("#distributor-phone-number-input", $form);
  const $phoneNumberErrorMessage = $("#distributor-phone-number-error-message", $form);

  phoneNumberFormatter($phoneNumberInput[0], $phoneNumberErrorMessage[0]);
});
