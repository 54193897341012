import {
  getQueryStringParameter
} from "../helpers";

$(document).ready(() => {
  const $modal = $(".modal#new-user-profile-modal");
  const showModalOnLoad = getQueryStringParameter("show_new_profile_modal");

  if ($modal.length > 0 && showModalOnLoad === "true") {
    $modal.modal("show");
  }
});
