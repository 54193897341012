import { getQueryStringParameter } from "../../helpers";

$(document).ready(() => {
  const $form = $("form#subscription-form");
  const step = getQueryStringParameter("id");

  if ($form.length === 0 || (step !== null && step !== "" && step !== "details" && !window.location.pathname.endsWith("new"))) {
    return;
  }

  const $userProfileSelect = $("#user-profile-selection select", $form);
  const $editUserProfileContainer = $("#edit-user-profile-container", $form);
  const $doNotEditUserProfileButton = $("#do-not-edit-profile-button", $editUserProfileContainer);
  const $subscriptionDetails = $("#subscription-details", $form);

  $userProfileSelect.on("change", (e) => {
    if (e.target.value === "") {
      $subscriptionDetails.addClass("d-none");
      $editUserProfileContainer.addClass("d-none");
    } else {
      $editUserProfileContainer.removeClass("d-none");
    }
  });

  $doNotEditUserProfileButton.on("click", (e) => {
    $editUserProfileContainer.addClass("d-none");
    $subscriptionDetails.removeClass("d-none");
  });
});
