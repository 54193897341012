import {
  getQueryStringParameter,
  clearInputField
} from "../../../helpers";

const setupQuestion = ($form, $radio, $formFields) => {
  $radio.on("change", (e) => {
    if (!e.target.checked) return;

    if (e.target.value === "true") {
      $formFields.removeClass("d-none");
    } else {
      $formFields.addClass("d-none");

      $("input", $formFields).each((_, inputElement) => {
        clearInputField(inputElement);
      });
    }
  });
};

$(document).ready(() => {
  const $form = $("form#legal-profile-form");
  const step = getQueryStringParameter("id");

  if ($form.length === 0 || step !== "expertise") {
    return;
  }

  const $experienceAsDirectorRadio = $("#experience_as_director_radio_buttons input[type=radio]", $form);
  const $experienceAsDirectorFormFields = $("#experience_as_director_fields", $form);

  setupQuestion($form, $experienceAsDirectorRadio, $experienceAsDirectorFormFields);

  const $experienceInCollectiveInvestmentUndertakingsRadio = $("#experience_in_collective_investment_undertakings_radio_buttons input[type=radio]", $form);
  const $experienceInCollectiveInvestmentUndertakingsFormFields = $("#experience_in_collective_investment_undertakings_fields", $form);

  setupQuestion($form, $experienceInCollectiveInvestmentUndertakingsRadio, $experienceInCollectiveInvestmentUndertakingsFormFields);

  const $recentTransactionsInFinancialInstrumentsRadio = $("#recent_transactions_in_financial_instruments_radio_buttons input[type=radio]", $form);
  const $recentTransactionsInFinancialInstrumentsFormFields = $("#recent_transactions_in_financial_instruments_fields", $form);

  setupQuestion($form, $recentTransactionsInFinancialInstrumentsRadio, $recentTransactionsInFinancialInstrumentsFormFields);

  const $investedTransactionsInCollectiveInvestmentUndertakingsRadio = $("#invested_transactions_in_collective_investment_undertakings_radio_buttons input[type=radio]", $form);
  const $transactionsInCollectiveInvestmentUndertakingsFormFields = $("#transactions_in_collective_investment_undertakings_fields", $form);

  setupQuestion($form, $investedTransactionsInCollectiveInvestmentUndertakingsRadio, $transactionsInCollectiveInvestmentUndertakingsFormFields);
});
