import { getQueryStringParameter } from "../../helpers";

const setFormSubmitButtonState = ($form) => {
  const $submitButton = $("input[type='submit']", $form);

  let $invalidTexts = $("#confirmation-input", $form).filter((_, element) => {
    return !$(element).hasClass("is-valid");
  });

  if ($invalidTexts.length === 0) {
    $submitButton.removeAttr("disabled");
  } else {
    $submitButton.attr("disabled", "disabled");
  }
};

const formatConfirmationString = (string) => {
  return string.normalize("NFD").replaceAll(/[^a-zA-Z0-9]/g, '').toLowerCase();
};

const validateConfirmation = ($form) => {
  const $confirmationLabel = $("#confirmation-label", $form);
  const $confirmationInput = $("#confirmation-input", $form);
  const $confirmationWarning = $("#confirmation-warning", $form);

  if (formatConfirmationString($confirmationInput.val()) === formatConfirmationString($confirmationLabel.text())) {
    $confirmationWarning.addClass("d-none");
    $confirmationInput.removeClass("is-invalid");
    $confirmationInput.addClass("is-valid");
  } else {
    $confirmationWarning.removeClass("d-none");
    $confirmationInput.addClass("is-invalid");
    $confirmationInput.removeClass("is-valid");
  }

  setFormSubmitButtonState($form);
};

$(document).ready(() => {
  const $form = $("form#subscription-form");
  const step = getQueryStringParameter("id");

  if ($form.length === 0 || step !== "agreements") {
    return;
  }

  const $confirmationInput = $("#confirmation-input", $form);

  $confirmationInput.on("input", (e) => {
    validateConfirmation($form);
  });
});
