import {
  clearInputField
} from "../../helpers";

import { escapeRegExp } from "../../helpers/escapeRegExp";

const paramsNamespace = "[documents_attributes]";
const paramsNamespaceForRegexp = escapeRegExp(paramsNamespace);

const buildOtherDocumentFormTemplate = ($form) => {
  const $otherDocumentForm = $("#other-documents .other-document-template", $form).first();

  $otherDocumentForm.remove();
  $otherDocumentForm.removeClass("d-none");

  $otherDocumentForm.removeClass("other-document-template");
  $otherDocumentForm.addClass("other-document");

  let $removeButtonWrapper = $(".remove-other-document-wrapper", $otherDocumentForm);
  let $removeButton = $(".remove-other-document", $removeButtonWrapper);

  $removeButton.on("click", (e) => {
    $(e.target).closest(".other-document").remove();
  });

  const newElementNameRegex = new RegExp(`${paramsNamespaceForRegexp}\\[\\d+\\]`);
  const documentTypeElementNameRegexp = new RegExp(`${paramsNamespaceForRegexp}\\[\\]\\[type\\]`);

  $("input", $otherDocumentForm).each((_, element) => {
    element.removeAttribute("id");
    element.name = element.name.replace(newElementNameRegex, `${paramsNamespace}[]`);

    // Don't remove the type of the document
    if (!(element.type === "hidden" && element.name.match(documentTypeElementNameRegexp))) {
      clearInputField(element);
    }
  });

  $("label", $otherDocumentForm).each((_, element) => {
    element.removeAttribute("for");
  });

  return $otherDocumentForm;
};

export const setupOtherDocumentsForm = ($form) => {
  const $otherDocumentFormTemplate = buildOtherDocumentFormTemplate($form, paramsNamespace);
  const $otherDocuments = $("#other-documents", $form);
  const otherDocument = ".other-document";

  const $addOtherDocumentButton = $("#add-other-document", $form);

  const $removeButtonWrapper = $(`${otherDocument} .remove-other-document-wrapper`, $otherDocuments);
  const $removeButton = $(".remove-other-document", $removeButtonWrapper);

  $removeButton.on("click", (e) => {
    $(e.target).closest(".other-document").remove();
  });

  const newElementNameRegex = new RegExp(`${paramsNamespaceForRegexp}\\[\\]`);

  $addOtherDocumentButton.on("click", (e) => {
    let $newDocumentForm = $otherDocumentFormTemplate.clone(true);
    let newID = Date.now()

    $("input", $newDocumentForm).each((_, element) => {
      element.name = element.name.replace(newElementNameRegex, `${paramsNamespace}[${newID}]`);
    });

    $otherDocuments.append($newDocumentForm);
  });
};
