import {
  getQueryStringParameter,
  clearInputField
} from "../../../helpers";

$(document).ready(() => {
  const $form = $("form#legal-profile-form");

  if ($form.length === 0 || getQueryStringParameter("id") !== "origin_of_funds") {
    return;
  }

  const $originOfFundsRadio = $("#origin-of-funds-radio-buttons input[type=radio]", $form);
  const $otherOriginOfFundsField = $("#other-origin-of-funds-field", $form);

  $originOfFundsRadio.on("change", (e) => {
    if (!e.target.checked) return;

    if (e.target.value === "outside_metropolitan_france") {
      $otherOriginOfFundsField.removeClass("d-none");
    } else {
      $otherOriginOfFundsField.addClass("d-none");

      $("input", $otherOriginOfFundsField).each((_, inputElement) => {
        clearInputField(inputElement);
      });
    }
  });

  const $sourceOfFundsRadio = $("#source-of-funds-radio-buttons input[type=radio]", $form);
  const $otherSourceOfFundsField = $("#other-source-of-funds-field", $form);

  $sourceOfFundsRadio.on("change", (e) => {
    if (!e.target.checked) return;

    if (e.target.value === "other") {
      $otherSourceOfFundsField.removeClass("d-none");
    } else {
      $otherSourceOfFundsField.addClass("d-none");

      $("input", $otherSourceOfFundsField).each((_, inputElement) => {
        clearInputField(inputElement);
      });
    }
  });

  const $investmentObjectivesCheckBoxes = $("#investment-objectives-checkboxes input[type=checkbox]", $form);
  const $otherInvestmentObjectivesField = $("#other-investment-objectives-field", $form);

  $investmentObjectivesCheckBoxes.on("change", (e) => {
    if (e.target.value !== "other") return;

    if (e.target.checked) {
      $otherInvestmentObjectivesField.removeClass("d-none");
    } else {
      $otherInvestmentObjectivesField.addClass("d-none");

      $("input", $otherInvestmentObjectivesField).each((_, inputElement) => {
        clearInputField(inputElement);
      });
    }
  });
});
