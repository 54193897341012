import {
  getQueryStringParameter,
  clearInputField
} from "../../helpers";

$(document).ready(() => {
  const $form = $("form#natural-profile-form, form#legal-profile-form");

  if ($form.length === 0 || getQueryStringParameter("id") !== "erisa_benefit_plan") {
    return;
  }

  const $usEmployeeBenefitPlansCheckBoxes = $("#us-employee-benefits-plans-checkboxes input[type=checkbox]", $form);
  const $multipleAccountTitleOneErisaPlanOwnershipRateFields = $("#multiple-account-title-one-erisa-plan-ownership-rate-fields", $form);

  $usEmployeeBenefitPlansCheckBoxes.on("change", (e) => {
    if (e.target.value !== "multiple_account_title_one_erisa_plan") return;

    if (e.target.checked) {
      $multipleAccountTitleOneErisaPlanOwnershipRateFields.removeClass("d-none");
    } else {
      $multipleAccountTitleOneErisaPlanOwnershipRateFields.addClass("d-none");

      $("input", $multipleAccountTitleOneErisaPlanOwnershipRateFields).each((_, inputElement) => {
        clearInputField(inputElement);
      });
    }
  });
});
