import { getQueryStringParameter } from "../../../helpers";
import { setupOtherDocumentsForm } from "../other_documents_form";

$(document).ready(() => {
  const $form = $("form#natural-profile-form");
  const step = getQueryStringParameter("id");

  if ($form.length === 0 || step !== "documents") {
    return;
  }

  setupOtherDocumentsForm($form);
});
