import {
  clearInputField,
  clearSelectField
} from "../../helpers";

export const setupFatcaFiscalCountries = ($form) => {
  const $addButton = $("#add-fatca-fiscal-country", $form);
  const fiscalCountryFields = ".fatca-fiscal-country-fields";
  const $fiscalCountryFields = $(fiscalCountryFields, $form);
  const $removeButtons = $(".remove-fatca-fiscal-country", $fiscalCountryFields);

  const fatcaNifNumberInput = ".fatca-nif-number input[type=text]";
  const fatcaResidenceWithoutNifCheckbox = ".fatca-residence-without-nif input[type=checkbox]";

  $(fatcaResidenceWithoutNifCheckbox, $fiscalCountryFields).on("change", (e) => {
    const $fatcaNifNumberInput = $(e.target).closest(fiscalCountryFields).find(fatcaNifNumberInput);

    if (e.target.checked) {
      $fatcaNifNumberInput.attr("disabled", "disabled");
      clearInputField($fatcaNifNumberInput[0]);
    } else {
      $fatcaNifNumberInput.removeAttr("disabled");
    }
  });

  $addButton.on("click", (e) => {
    e.preventDefault();

    const $hiddenFiscalCountryFields = $fiscalCountryFields.filter(".d-none");

    if ($hiddenFiscalCountryFields.length == 1) {
      $addButton.addClass("d-none");
    };

    $hiddenFiscalCountryFields.first().removeClass("d-none");
  });

  $removeButtons.on("click", (e) => {
    e.preventDefault();

    const $removeButton = $(e.target);
    const $fields = $removeButton.closest(fiscalCountryFields);
    const $fieldsFeedback = $(".invalid-feedback", $fields);
    const $fatcaNifNumberInput = $(fatcaNifNumberInput, $fields);

    $fatcaNifNumberInput.removeAttr("disabled");
    $fields.addClass("d-none");
    $fieldsFeedback.addClass("d-none");

    $("input", $fields).each((_, inputElement) => {
      clearInputField(inputElement);
    });

    const changeEvent = new Event("change");

    $("select", $fields).each((_, inputElement) => {
      clearInputField(inputElement);
      inputElement.selectedIndex = 0
      inputElement.dispatchEvent(changeEvent);
    });

    $addButton.removeClass("d-none");
  });

  const hasOtherFatcaFiscalResidences = "#has-other-fatca-fiscal-residences";
  const $hasOtherFatcaFiscalResidences = $(`${hasOtherFatcaFiscalResidences} input[type=radio]`, $form);
  const $hasOtherFatcaFiscalResidencesFeedback = $(`${hasOtherFatcaFiscalResidences} > .invalid-feedback`, $form);

  const $otherFatcaFiscalResidencesContainer = $("#other-fatca-fiscal-residences-container", $form);
  const $otherFatcaFiscalResidencesFeedback = $(".invalid-feedback", $otherFatcaFiscalResidencesContainer);

  $hasOtherFatcaFiscalResidences.on("change", (e) => {
    if (!e.target.checked) return;

    if (e.target.value === "true") {
      $otherFatcaFiscalResidencesContainer.removeClass("d-none");
      $addButton.removeClass("d-none");
    } else {
      $otherFatcaFiscalResidencesContainer.addClass("d-none");
      $hasOtherFatcaFiscalResidencesFeedback.addClass("d-none");
      $otherFatcaFiscalResidencesFeedback.addClass("d-none");

      $(fiscalCountryFields, $otherFatcaFiscalResidencesContainer).each((_, element) => {
        const $element = $(element);

        $(fatcaNifNumberInput, $element).removeAttr("disabled");
        $element.addClass("d-none");

        $("input", $element).each((_, inputElement) => {
          clearInputField(inputElement);
        });

        const changeEvent = new Event("change");

        $("select", $element).each((_, inputElement) => {
          clearInputField(inputElement);
          inputElement.selectedIndex = 0
          inputElement.dispatchEvent(changeEvent);
        });

        $addButton.addClass("d-none");
      });
    }
  });

  $form.on("submit", (e) => {
    $(".fatca-fiscal-country-fields.d-none select option[selected]", $form).each((_, option) => {
      option.value = "";
    });
  });
};
