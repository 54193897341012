import {
  clearInputField,
  clearSelectField,
} from "../../helpers";

const clearUnusedPoliticallyExposedPersonFormFields = ($form, $politicallyExposedPersonRadio, formFieldsSelector) => {
  $politicallyExposedPersonRadio.each((_, element) => {
    if (element.value !== "true") {
      $(`${formFieldsSelector} input`, $form).each((_, inputElement) => {
        clearInputField(inputElement);
      });

      $(`${formFieldsSelector} select`, $form).each((_, selectElement) => {
        clearSelectField(selectElement);
      });
    }
  });
};

export const setupPoliticallyExposedForm = ($form) => {
  const $politicallyActiveRadio = $(".politically-active-radio-buttons input[type=radio]", $form);
  const politicallyActiveFormFields = ".politically-active-fields";
  const $politicallyActiveFormFields = $(politicallyActiveFormFields, $form);

  $politicallyActiveRadio.on("change", (e) => {
    if (!e.target.checked) return;

    if (e.target.value === "true") {
      $politicallyActiveFormFields.removeClass("d-none");
    } else {
      $politicallyActiveFormFields.addClass("d-none");
      clearUnusedPoliticallyExposedPersonFormFields($form, $politicallyActiveFormFields, politicallyActiveFormFields);
    }
  });

  const $spousePoliticallyActiveRadio = $(".spouse-politically-active-radio-buttons input[type=radio]", $form);
  const spousePoliticallyActiveFormFields = ".spouse-politically-active-fields";
  const $spousePoliticallyActiveFormFields = $(spousePoliticallyActiveFormFields, $form);

  $spousePoliticallyActiveRadio.on("change", (e) => {
    if (!e.target.checked) return;

    if (e.target.value === "true") {
      $spousePoliticallyActiveFormFields.removeClass("d-none");
    } else {
      $spousePoliticallyActiveFormFields.addClass("d-none");
      clearUnusedPoliticallyExposedPersonFormFields($form, $spousePoliticallyActiveFormFields, spousePoliticallyActiveFormFields);
    }
  });

  const $closePEPPoliticallyActiveRadio = $(".close-politically-exposed-person-politically-active-radio-buttons input[type=radio]", $form);
  const closePEPPoliticallyActiveFormFields = ".close-politically-exposed-person-politically-active-fields";
  const $closePEPPoliticallyActiveFormFields = $(closePEPPoliticallyActiveFormFields, $form);

  $closePEPPoliticallyActiveRadio.on("change", (e) => {
    if (!e.target.checked) return;

    if (e.target.value === "true") {
      $closePEPPoliticallyActiveFormFields.removeClass("d-none");
    } else {
      $closePEPPoliticallyActiveFormFields.addClass("d-none");
      clearUnusedPoliticallyExposedPersonFormFields($form, $closePEPPoliticallyActiveFormFields, closePEPPoliticallyActiveFormFields);
    }
  });

  const $politicallyExposedPersonRadio = $(".politically-exposed-person-radio-buttons input[type=radio]", $form);
  const politicallyExposedPersonFormFields = ".politically-exposed-person-form-fields";
  const $politicallyExposedPersonFormFields = $(politicallyExposedPersonFormFields, $form);

  $politicallyExposedPersonRadio.on("change", (e) => {
    if (!e.target.checked) return;

    if (e.target.value === "true") {
      $politicallyExposedPersonFormFields.removeClass("d-none");
    } else {
      $politicallyExposedPersonFormFields.addClass("d-none");
      $politicallyActiveFormFields.addClass("d-none");
      $spousePoliticallyActiveFormFields.addClass("d-none");
      $closePEPPoliticallyActiveFormFields.addClass("d-none");

      clearUnusedPoliticallyExposedPersonFormFields($form, $politicallyExposedPersonRadio, politicallyExposedPersonFormFields);
    }
  });
};

export const resetPoliticallyExposedForm = ($form) => {
  const $politicallyExposedPersonFormFields = $(".politically-exposed-person-form-fields", $form);
  const $politicallyActiveFormFields = $(".politically-active-fields", $form);
  const $spousePoliticallyActiveFormFields = $(".spouse-politically-active-fields", $form);
  const $closePEPPoliticallyActiveFormFields = $(".close-politically-exposed-person-politically-active-fields", $form);

  $politicallyExposedPersonFormFields.addClass("d-none");
  $politicallyActiveFormFields.addClass("d-none");
  $spousePoliticallyActiveFormFields.addClass("d-none");
  $closePEPPoliticallyActiveFormFields.addClass("d-none");

  $("input", $politicallyExposedPersonFormFields).each((_, inputElement) => {
    clearInputField(inputElement);
  });

  $("select", $politicallyActiveFormFields).each((_, selectElement) => {
    clearSelectField(selectElement);
  });
};
