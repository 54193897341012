import {
  getQueryStringParameter,
  clearInputField
} from "../../helpers";

$(document).ready(() => {
  const $form = $("form#natural-profile-form, form#legal-profile-form");

  if ($form.length === 0 || getQueryStringParameter("id") !== "benefit_plan") {
    return;
  }

  const $nonUsEmployeeBenefitPlanRadioButtons = $("#non-us-employee-benefits-plan-radio-buttons input[type=radio]", $form);
  const $nonUsEmployeeErisaBenefitPlanInterestsRateFields = $("#non-us-employee-erisa-benefit-plan-interests-rate-fields", $form);

  $nonUsEmployeeBenefitPlanRadioButtons.on("change", (e) => {
    if (e.target.value === "erisa_plan" && e.target.checked) {
      $nonUsEmployeeErisaBenefitPlanInterestsRateFields.removeClass("d-none");
    } else {
      $nonUsEmployeeErisaBenefitPlanInterestsRateFields.addClass("d-none");

      $("input", $nonUsEmployeeErisaBenefitPlanInterestsRateFields).each((_, inputElement) => {
        clearInputField(inputElement);
      });
    }
  });
});
