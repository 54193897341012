// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag "rails.png" %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context("../images", true)
// const imagePath = (name) => images(name, true)

import "popper.js";
import "bootstrap";
import toastr from "toastr";
import "select2";
import "@fortawesome/fontawesome-free/js/all";

// Legal profile pipeline
import "./application/users/profiles/legal/investor_identification_step.js";
import "./application/users/profiles/legal/professional_client_classification_step.js";
import "./application/users/profiles/legal/retail_client_classification_step.js";
import "./application/users/profiles/legal/financial_information_step.js";
import "./application/users/profiles/legal/knowledge_step.js";
import "./application/users/profiles/legal/expertise_step.js";
import "./application/users/profiles/legal/tax_details_step.js";
import "./application/users/profiles/legal/ownership_step.js";
import "./application/users/profiles/legal/origin_of_funds_step.js";
import "./application/users/profiles/legal/us_supplement_step.js";
import "./application/users/profiles/legal/documents_step.js";

// Natural profile pipeline
import "./application/users/profiles/natural/investor_identification_step.js";
import "./application/users/profiles/natural/classification_step.js";
import "./application/users/profiles/natural/financial_information_step.js";
import "./application/users/profiles/natural/knowledge_step.js";
import "./application/users/profiles/natural/expertise_step.js";
import "./application/users/profiles/natural/tax_details_step.js";
import "./application/users/profiles/natural/origin_of_funds_step.js";
import "./application/users/profiles/natural/us_supplement_step.js";
import "./application/users/profiles/natural/documents_step.js";

// Common user profile pipelines steps
import "./application/users/profiles/contacts_step.js";
import "./application/users/profiles/erisa_benefit_plan_step.js";
import "./application/users/profiles/benefit_plan_step.js";
import "./application/users/profiles/bad_actor_rules_step.js";
import "./application/users/profiles/representations_and_warranties_step.js";

// Profiles index page
import "./application/users/profiles.js";

// Subscription pipeline
import "./application/users/subscriptions/details_step.js";
import "./application/users/subscriptions/agreements_step.js";

// Completed subscriptions
import "./application/users/completed_subscriptions/edit.js";

// Distributors account info page and invitation form
import "./application/distributors.js";

// Distributors investor creation form
import "./application/distributors/users.js";

toastr.options.toastClass = "toastr";

$(document).ready(() => {
  if ($(".flash.info")[0]) {
    toastr["info"]($(".flash.info").html());
  }
  if ($(".flash.notice")[0]) {
    toastr["info"]($(".flash.notice").html());
  }
  if ($(".flash.success")[0]) {
    toastr["success"]($(".flash.success").html());
  }
  if ($(".flash.error")[0]) {
    toastr["error"]($(".flash.error").html());
  }
  if ($(".flash.alert")[0]) {
    toastr["error"]($(".flash.alert").html());
  }

  $("select.custom-select").select2();

  $("body").on("change", ".custom-file-input", function() {
    if ($(this)[0].files[0] !== undefined) {
      $(this)
        .siblings(".custom-file-label")
        .html($(this)[0].files[0].name);
    }
  });
});
