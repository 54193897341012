import { getQueryStringParameter } from "../../../helpers";
import { setupPoliticallyExposedForm } from "../politically_exposed_person_form.js";
import { phoneNumberFormatter } from "../../../phoneNumberFormatter.js";

$(document).ready(() => {
  const $form = $("form#natural-profile-form");
  const step = getQueryStringParameter("id");

  if ($form.length === 0 || (step !== null && step !== "" && step !== "investor_identification" && !window.location.pathname.endsWith("new"))) {
    return;
  }

  const phoneNumberInput = $form[0].querySelector("#phone_number_input");
  const phoneNumberErrorMesage = $form[0].querySelector("#phone_number_error_message");

  phoneNumberFormatter(phoneNumberInput, phoneNumberErrorMesage);
  setupPoliticallyExposedForm($form);

  const $limitedPartnerIsBeneficiaryRadioButtons = $("#limited-partner-is-beneficiary-radio-buttons input[type=radio]", $form);
  const $limitedPartnerBeneficiaryDetails = $("#limited-partner-beneficiary-details", $form);

  $limitedPartnerIsBeneficiaryRadioButtons.on("change", (e) => {
    $limitedPartnerBeneficiaryDetails.toggleClass("d-none", !(e.target.value === "false" && e.target.checked));
  });
});
