import intlTelInput from "intl-tel-input";
import telUtils from "intl-tel-input/build/js/utils";

const defaultCountry = "fr";

export const phoneNumberFormatter = (input, errorMessage) => {
  const hideErrorMessage = (errorMsg) => {
    if (errorMsg) {
      errorMsg.classList.add("d-none");
    }
  };

  const showErrorMessage = (errorMsg) => {
    if (errorMsg) {
      errorMsg.classList.remove("d-none");
    }
  };

  const iti = intlTelInput(input, {
    utilsScript: telUtils,
    formatOnDisplay: false,
    preferredCountries: [defaultCountry]
  });

  const updatePhoneNumber = (input) => {
    const intNumber = iti.getNumber();
    input.value = intNumber;
  };

  input.addEventListener("change", () => {
    updatePhoneNumber(input);
  });

  input.addEventListener("countrychange", () => {
    updatePhoneNumber(input);
  });

  input.addEventListener("blur", () => {
    hideErrorMessage(errorMessage);

    if (input.value.trim() && !iti.isValidNumber()) {
      showErrorMessage(errorMessage);
    }
  });

  input.addEventListener("load", hideErrorMessage(errorMessage));
  input.addEventListener("change", hideErrorMessage(errorMessage));
  input.addEventListener("keyup", hideErrorMessage(errorMessage));

  return iti;
};

export const clearPhoneNumberInput = (iti) => {
  iti.setCountry(defaultCountry);
  iti.setNumber("");
};
