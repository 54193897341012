import {
  getQueryStringParameter,
  clearInputField
} from "../../../helpers";

$(document).ready(() => {
  const $form = $("form#natural-profile-form");
  const step = getQueryStringParameter("id");

  if ($form.length === 0 || step !== "knowledge") {
    return;
  }

  const $investmentHistoryCheckBoxes = $("#investment_history_fields input[type=checkbox]", $form);
  const $privateEquityFields = $("#private_equity_fields", $form);

  $investmentHistoryCheckBoxes.on("change", (e) => {
    if (e.target.value !== "private_equity") return;

    if (e.target.checked) {
      $privateEquityFields.removeClass("d-none");
    } else {
      $privateEquityFields.addClass("d-none");

      $("input", $privateEquityFields).each((_, inputElement) => {
        clearInputField(inputElement);
      });
    }
  });
});
